<template>
    <div>
        <el-drawer title="广告结构设置"  
            :visible="adConfigShow" 
            direction="rtl" 
            @close="close" 
            wrapperClosable 
            size="1200px"
            :append-to-body="true"
            :modal-append-to-body="false">
            <div class="drawerCon">
                <div class="campainCon">
                    <el-form v-model="adSetting" label-position="top">
                        <el-form-item label="">
                            <el-radio-group v-model="adSetting.radio">
                                <el-radio :label="0">为每个受众创建一个广告组</el-radio>
                                <el-radio :label="1">为每个广告创建一个广告组</el-radio>
                                <el-radio :label="2" disabled>为每个受众创建一个广告系列</el-radio>
                            </el-radio-group>
                        </el-form-item>
                
                    </el-form>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <p>
                    <el-checkbox v-model="saveMine">保存为广告账号默认广告结构
                        <el-tooltip class="item" effect="dark" content="保存为广告账号默认广告结构，下次该广告账号创建广告系列时，系统默认使用此广告结构。你可以在广告资产中分别对各广告账号设置广告结构。" placement="top-start"><i class="el-icon-warning-outline"></i></el-tooltip>
                    </el-checkbox>
                </p>
                <div>
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" @click="sure">保存</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {getSetsByAccounts,getDicts,setContent} from '@/api/creatAd.js'
    export default {
        props:['adConfigShow','dictsInit','fbAccountId','accountSet'],
        data() {
            return {
                adSetting:{
                    radio:0
                },
                saveMine:false,
            }
        },
        components:{
        },
        methods: {
            // 关闭弹窗
            close(){
                this.$emit('close','adConfigShow')
            },
            // 点击保存
            sure(){
                if(this.saveMine){
                    setContent({split_type:this.adSetting.radio,accounts:[this.fbAccountId]}).then(res=>{
                        if(res.code == 200){
                            this.$message({
                                type:'success',
                                message:'保存成功'
                            })
                        }
                    })
                }
                this.$emit('setsplitType',this.adSetting.radio)
                this.close();
            },
            
        },
        watch: {
            accountSet:{
                handler(){
                    this.adSetting.radio = this.accountSet.split_type
                }
            }
        },
    }
</script>
<style lang="scss" scope>
    .campainCon{
        padding: 0 20px;
        .title{
            line-height: 40px;
            color: #666;
            font-size: 14px;
        }
        .paramsList{
            .paramsItem{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .el-input,.el-select{
                    width:360px;
                }
                span{
                    margin: 0 5px;
                    cursor: pointer;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    color: #666;
                    cursor: pointer;
                }
            }
        }
        .el-input--suffix .el-input__inner{
            padding-right: 0;
        }
        .el-form-item{
            p{
                font-size: 12px;
                color: #999;
                span{
                    margin: 0 5px;
                }
            }
        }
    }
</style>